import React, { useState } from "react";
import { Tabs } from "antd";
import Menu from '../menu/index.tsx'
import FormTable from "../formTable/index.tsx";
import AccountManagement from "../accountManagement/index.tsx";

const App = ({info}) => {
    const [menu, _menu] = useState("");

	const onChange = (key) => {
		console.log(key);
	};

	const bindMenu = (item) => {
		_menu(item);
	};
	const items = [
		{
			key: "1",
			label: "推文管理",
			children: (
				<div className="content">
					<div className="menu">
						<Menu menu={menu} bindMenu={bindMenu} info={info} />
					</div>
					<div className="formTable">
						<FormTable menu={menu} info={info} />
					</div>
				</div>
			),
		},
		{
			key: "2",
			label: "账户管理",
			children: <AccountManagement/>,
            disabled: !(info?.role == "admin"),

		},
	];
	return <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;
};
export default App;
