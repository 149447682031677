import React, { useState } from "react";
import { Button, Checkbox, Col, Row } from "antd";

export default function CheckboxContent({
	btnTxt,
	onOk,
	load,
	checkboxList,
	checkedValues,
	_checkedValue,
}) {
	const onChange = (values) => {
		console.log("values", values);
		_checkedValue(values);
	};

	return (
		<div className="CheckboxContent">
			<div className="CheckboxContentTop">
				<Checkbox.Group
					value={checkedValues}
					onChange={onChange}
					style={{ width: "100%" }}
				>
					<Row>
						{checkboxList?.map((item) => {
							return (
								<Col
									span={8}
									key={item.twitterUserName}
									style={{ display: "flex" }}
								>
									<Checkbox value={item.twitterUserName}>
										{item.twitterUserName}
									</Checkbox>
								</Col>
							);
						})}
					</Row>
				</Checkbox.Group>
			</div>

			<Button
				loading={load}
				className="CheckboxContentBtn"
				onClick={() => onOk(checkedValues)}
			>
				{btnTxt}
			</Button>
		</div>
	);
}
