import axios from "axios";
import { getItemWithExpiry } from "../utils/index.ts";

// 正式
export const apiUrl = "api.pepeasian.com";

export const baseURL = `https://${apiUrl}`;

// launchpad
const launchpadApi = new axios.Axios({
	baseURL: baseURL,
	// withCredentials: true, // 如果你的请求需要带上凭证（比如 cookies）
	headers: {
		"Content-Type": "application/json",
	},
});

// 请求拦截器
launchpadApi.interceptors.request.use(
	(config) => {
		const val = getItemWithExpiry("TIMED_TWEETS");
		let token = JSON.parse(val)?.token;
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		// 处理请求错误
		return Promise.reject(error);
	}
);

// 响应拦截器
launchpadApi.interceptors.response.use(
	(response) => {
		// console.log("response", response);

		// const result: any = response?.data ? JSON.parse(response?.data) : null;
		// // 在响应之前可以在这里做一些处理
		// if (result?.errorCode == 0) {
		// 	return JSON.parse(response?.data);
		// } else {
		// 	return Promise.reject(result?.message);
		// }
		return response?.data ? JSON.parse(response?.data) : null;
	},
	(error) => {
		// 处理响应错误
		return Promise.reject(error);
	}
);

//登陆
export async function bindAuthorization(data) {
	let str = JSON.stringify(data);
	return await launchpadApi.post(`/timed-tweets/authorization`, str);
}

//添加推特账户信息
export async function UserAdd(data) {
	let str = JSON.stringify(data);
	return await launchpadApi.post(`/timed-tweets/user/add`, str);
}

//获取推特账户信息
export async function UserGet() {
	return await launchpadApi.get(`/timed-tweets/user/get`);
}

//删除推特账户信息
export async function UserDel(data) {
	let str = JSON.stringify(data);
	return await launchpadApi.post(`/timed-tweets/user/delete`, str);
}

//添加定时推文
export async function tweetsAdd(data) {
	let str = JSON.stringify(data);
	return await launchpadApi.post(`/timed-tweets/tweets/add`, str);
}

//获取定时推文
export async function tweetsGet(twitterUserName) {
	return await launchpadApi.get(
		`/timed-tweets/tweets/get?twitterUserName=${twitterUserName}`
	);
}

//删除定时推文
export async function tweetsDel(data) {
	let str = JSON.stringify(data);
	return await launchpadApi.post(`/timed-tweets/tweets/delete`, str);
}

//更新定时推文
export async function tweetsUpdate(data) {
	let str = JSON.stringify(data);
	return await launchpadApi.post(`/timed-tweets/tweets/update`, str);
}

//更新账户
export async function userUpdate(data) {
	let str = JSON.stringify(data);
	return await launchpadApi.post(`/timed-tweets/user/update`, str);
}



//审核推文
export async function tweetsAudit(data) {
	let str = JSON.stringify(data);
	return await launchpadApi.post(`/timed-tweets/tweets/audit`, str);
}

// 批量添加账户-Twitter关系
export async function relationAdd(data) {
	let str = JSON.stringify(data);
	return await launchpadApi.post(`/timed-tweets/relation/add`, str);
}

//批量删除账户-Twitter关系
export async function relationDel(data) {
	let str = JSON.stringify(data);
	return await launchpadApi.post(`/timed-tweets/relation/delete`, str);
}

//获取指定地址的所有Twitter关系
// - add ：管理员必填  true 获取指定地址已关联账户   false 获取指定地址未关联账户     用户不用填
//- address 管理员必填   用户钱包地址  用户不用填
export async function relationGet(add = true, address) {
	return await launchpadApi.get(
		`/timed-tweets/relation/get?add=${add}&address=${address}`
	);
}


//管理员获取全部用户地址
export async function getUser() {
	return await launchpadApi.get(
		`/timed-tweets/user/address`
	);
}

