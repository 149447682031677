import { Button, Card, Input, message, Popconfirm, Select, Switch } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
	getUser,
	relationGet,
	tweetsUpdate,
	UserAdd,
	UserDel,
	UserGet,
	userUpdate,
} from "../../api";
import { omitCharacters } from "../../utils/index.ts";

const { Option } = Select;

function Menu({ bindMenu, menu, info }: any) {
	const [list, _list] = useState([]);
	const inputRef = useRef(null);
	const [selVal, _selVal] = useState("0");

	const [selUserList, _selUserList] = useState([]);

	const [selUser, _selUser] = useState("0");

	const getList = async () => {
		if (info?.role == "admin"&&selUser == "0") {
			let res = await UserGet();
			let list = res?.data;
			_list(list);
			return list[0];

		} else {
			let add = info?.role == "admin" ? true : false;

			let address = info?.role == "admin" ? selUser : "";
			let res = await relationGet(add, address);
			let list = res?.data;
			_list(list);
			return list[0];
		}
	};

	const bindList = async () => {
		let res = await getList();
		bindMenu(res);
	};

	useEffect(() => {
		bindList();
	}, [selUser]);

	// 获取所有用户
	const getUserList = async () => {
		let res = await getUser();
		console.log("res", res);
		let list = res?.data;

		_selUserList(list);
	};

	useEffect(() => {
		if (info?.role == "admin") {
			getUserList();
		}
	}, [info]);

	const bindAdd = async () => {
		let val = inputRef?.current?.input?.value;
		console.log(selVal);

		if (val) {
			let data = { twitterUserName: val, twitterUserType: selVal };
			let res = await UserAdd(data);
			console.log("res", res);
			if (res?.code == 200) {
				getList();
			} else {
				message.error(res.message);
			}
		}
	};

	const bindTweetsUpdate = async (data, e) => {
		let newData = {
			id:data?.id,
			twitterUserType: e ? 0 : 1,
		};
		let res = await userUpdate(newData);
		console.log("res", res);
		getList();
	};

	const bindDel = async (item) => {
		let data = {
			id: item.id,
		};
		let res = await UserDel(data);
		console.log("res", res);
		getList();
	};

	return (
		<Card
			title={
				<div className="menuCardTitle">
					<div>选择用户：</div>
					{info?.role == "admin" && (
						<Select
							style={{ width: 150 }}
							defaultValue="0"
							onChange={(e) => {
								_selUser(e);
							}}
						>
							<Option value={"0"}>All</Option>

							{selUserList?.map((item) => {
								return (
									<Option key={item} value={item}>
										{omitCharacters(item, 5)}
									</Option>
								);
							})}
						</Select>
					)}
				</div>
			}
			bordered={false}
			style={{ width: 300, padding: 0 }}
		>
			{info?.role == "admin" && (
				<div className="menumenuTop ">
					<Input
						ref={inputRef}
						addonAfter={
							<div className="menuAdminBtn">
								<Select
									defaultValue="0"
									onChange={(e) => {
										_selVal(e);
									}}
								>
									<Option value="0">官方</Option>
									<Option value="1">非官方</Option>
								</Select>
								<Button
									className="menuAdminBtnBtn"
									onClick={bindAdd}
								>
									ADD
								</Button>
							</div>
						}
					/>
				</div>
			)}
			<div className="menumenu">
				{list?.map((item: any) => {
					return (
						<div
							key={item.id}
							className={`${
								menu?.twitterUserName == item.twitterUserName
									? "menumenuItemActive menumenuItem"
									: "menumenuItem"
							}`}
							onClick={() => bindMenu(item)}
						>
							{item.twitterUserName}{" "}
							{info?.role == "admin"&&<Switch
								onClick={(_, e) => {
									e.stopPropagation();
									e.preventDefault();
								}}
								onChange={(e) => bindTweetsUpdate(item, e)}
								style={{ marginLeft: 10 }}
								checkedChildren="官方"
								unCheckedChildren="非官方"
								defaultChecked={item?.twitterUserType == "0"}
							/>}
							{info?.role == "admin" && (
								<Popconfirm
									title="Delete the account"
									description="Are you sure you want to delete this account?"
									onConfirm={() => {
										bindDel(item);
									}}
									okText="Yes"
									cancelText="No"
								>
									<div
										className="menumenuItemDel"
										onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
										}}
									>
										<CloseCircleOutlined
											style={{ fontSize: 20 }}
										/>
									</div>
								</Popconfirm>
							)}
						</div>
					);
				})}
			</div>
		</Card>
	);
}

export default Menu;
