import { EditableProTable } from "@ant-design/pro-components";
import { Button, Input, message, Popconfirm, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
	baseURL,
	tweetsAdd,
	tweetsAudit,
	tweetsDel,
	tweetsGet,
	tweetsUpdate,
} from "../../api";
import { getItemWithExpiry } from "../../utils/index.ts";
import dayjs from "dayjs";

export default ({ menu, info }) => {
	const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
	const [dataSource, setDataSource] = useState([]);
	const [position, setPosition] = useState<"top" | "bottom" | "hidden">(
		"bottom"
	);
	const [load, _load] = useState(false);

	let twitterUserName = menu?.twitterUserName;

	const imgRef = useRef(null);

	const val = getItemWithExpiry("TIMED_TWEETS");
	let token = JSON.parse(val)?.token;
	const handleUploadChange = async (info, recordId) => {
		if (info.file.status === "done") {
			let imageUrl = `${baseURL}/timed-tweets/image/${info.file.response.imageName}`;
			message.success("图片上传成功");
			imgRef.current = imageUrl;
			const updatedDataSource = dataSource.map((item) => {
				if (item.id === recordId) {
					return {
						...item,
						image: imageUrl,
					};
				}
				return item;
			});
			_load(false);

			setDataSource(updatedDataSource);
		} else {
			_load(false);
			message.error("上传失败");
		}
	};

	// const
	const getList = async (menu) => {
		let { twitterUserName } = menu;
		console.log('menu',menu);

		let res = await tweetsGet(twitterUserName);
		if (res?.code == 200) {
			let list = res?.data;
			setDataSource(list);
		} else {
			message.error(res.message);
		}
	};

	const bindTweetsAdd = async (data) => {
		let newData = {
			twitterUserName: twitterUserName,
			...data,
		};
		let res = await tweetsAdd(newData);

		getList(menu);
	};

	const bindTweetsUpdate = async (data) => {
		let newData = {
			twitterUserName: twitterUserName,
			...data,
		};
		let res = await tweetsUpdate(newData);

		getList(menu);
	};
	const bindTweetsDel = async (item) => {
		let res = await tweetsDel({
			id: item.id,
		});

		getList(menu);
	};

	const bindAudit = async (item) => {
		let res = await tweetsAudit({
			id: item.id,
		});
		getList(menu);
	};

	useEffect(() => {
		if (menu) {
			
			getList(menu);
		} else {
			setDataSource([]);
		}
	}, [menu]);

	const columns = [
		{
			title: "推文内容",
			dataIndex: "tweet",
			formItemProps: (form, { rowIndex }) => {
				return {
					rules:
						rowIndex > 1
							? [{ required: true, message: "此项为必填项" }]
							: [],
				};
			},
			width: "30%",
			renderFormItem: (item, { isEditable }) => {
				return (
					<Input.TextArea
						rows={6} // 设置多行文本框的行数
						placeholder="请输入推文内容"
					/>
				);
			},
		},
		{
			title: "推文图片",
			dataIndex: "image",
			render: (image, record) => {
				return (
					<div>
						<img
							style={{ width: 50, height: 50, borderRadius: 4 }}
							src={image}
							alt=""
						/>
						{record.isEditing ? (
							<Upload
								headers={{
									Authorization: `Bearer ${token}`,
								}}
								action={`${baseURL}/timed-tweets/image/upload`}
								beforeUpload={(file) => {
									const formData = new FormData();
									formData.append("image", file); // 将文件字段名改为 'image'
									_load(true);
									// 创建 XMLHttpRequest 并手动发送请求
									const xhr = new XMLHttpRequest();
									xhr.open(
										"POST",
										`${baseURL}/timed-tweets/image/upload`,
										true
									);
									xhr.setRequestHeader(
										"Authorization",
										`Bearer ${token}`
									);

									xhr.onload = () => {
										if (xhr.status === 200) {
											// 上传成功
											handleUploadChange(
												{
													file: {
														status: "done",
														response: JSON.parse(
															xhr.response
														),
													},
												},
												record.id
											);
										} else {
											// 上传失败
											handleUploadChange(
												{ file: { status: "error" } },
												record.id
											);
										}
									};

									xhr.onerror = () => {
										// 上传错误处理
										handleUploadChange(
											{ file: { status: "error" } },
											record.id
										);
									};

									xhr.send(formData); // 使用 FormData 发送请求

									// 阻止默认的上传行为
									return false;
								}}
								showUploadList={false}
							>
								<Button loading={load}>上传图片</Button>
							</Upload>
						) : null}
					</div>
				);
			},
			renderFormItem: (_, { isEditable }) => {
				return isEditable ? (
					<Upload
						headers={{
							Authorization: `Bearer ${token}`,
						}}
						beforeUpload={(file) => {
							const formData = new FormData();
							formData.append("image", file); // 将文件字段名改为 'image'
							_load(true);

							// 创建 XMLHttpRequest 并手动发送请求
							const xhr = new XMLHttpRequest();
							xhr.open(
								"POST",
								`${baseURL}/timed-tweets/image/upload`,
								true
							);
							xhr.setRequestHeader(
								"Authorization",
								`Bearer ${token}`
							);

							xhr.onload = () => {
								if (xhr.status === 200) {
									// 上传成功
									handleUploadChange(
										{
											file: {
												status: "done",
												response: JSON.parse(
													xhr.response
												),
											},
										},
										_?.entity?.id
									);
								} else {
									// 上传失败
									handleUploadChange(
										{ file: { status: "error" } },
										_?.entity?.id
									);
								}
							};

							xhr.onerror = () => {
								// 上传错误处理
								handleUploadChange(
									{ file: { status: "error" } },
									_?.entity?.id
								);
							};

							xhr.send(formData); // 使用 FormData 发送请求

							// 阻止默认的上传行为
							return false;
						}}
						action={`${baseURL}/timed-tweets/image/upload`}
						showUploadList={false}
					>
						<Button loading={load}>上传图片</Button>
					</Upload>
				) : null;
			},
		},

		{
			title: "执行时间",
			dataIndex: "timed",
			valueType: "dateTime",
			renderText: (text) =>
				dayjs.unix(text).format("YYYY-MM-DD HH:mm:ss"),
		},
		{
			title: "状态",
			key: "status",
			dataIndex: "status",
			valueType: "select",
			readonly: true,
			valueEnum: {
				0: { text: "未审核", status: "Default" },
				1: {
					text: "已审核",
					status: "Success",
				},
				2: {
					text: "已发推",
					status: "Success",
				},
				3: {
					text: "发推失败",
					status: "Error",
				},
			},
		},
		{
			title: "操作",
			valueType: "option",
			width: 200,
			render: (text, record, _, action) => [
				<a
					key="editable"
					onClick={() => {
						action?.startEditable?.(record.id);
					}}
				>
					编辑
				</a>,
				<Popconfirm
					title="Delete the task"
					description="Are you sure to delete this task?"
					onConfirm={() => {
						bindTweetsDel(record);
					}}
					okText="Yes"
					cancelText="No"
				>
					<a key="delete">删除</a>
				</Popconfirm>,

				<a
					hidden={
						(menu?.twitterUserType == "0" &&
							(!(info?.role == "admin") ||
								record?.status != 0)) ||
						(menu?.twitterUserType == "1" && record?.status != 0)
					}
					key="submit"
					onClick={() => {
						bindAudit(record);
					}}
				>
					通过
				</a>,
			],
		},
	];

	return (
		<>
			<EditableProTable
				rowKey="id"
				headerTitle=""
				scroll={{
					x: 960,
				}}
				recordCreatorProps={
					position !== "hidden"
						? {
								position: position as "top",
								record: () => ({
									id: (Math.random() * 1000000).toFixed(0),
									status: 0, // 默认状态为 0
									timed: Math.floor(Date.now() / 1000), // 默认当前时间戳（秒级）
								}),
						  }
						: false
				}
				loading={false}
				toolBarRender={false}
				columns={columns}
				value={dataSource}
				onChange={setDataSource}
				editable={{
					type: "multiple",
					editableKeys,
					onSave: async (rowKey, data, row) => {
						const isEditing = dataSource.some(
							(item) => item.id === rowKey
						);

						let imageUrl = imgRef.current || data.image;
						// 将日期时间转换为秒级时间戳
						const timestampInSeconds = dayjs(data.timed).unix();
						const newData = {
							...data,
							timed: timestampInSeconds, // 替换为秒级时间戳
							status: data.status !== undefined ? data.status : 0, // 如果 status 为空，则设置为 0
							image: imageUrl, // 确保image字段包含图片的URL
						};
						if (isEditing) {
							await bindTweetsUpdate(newData);
						} else {
							await bindTweetsAdd(newData);
						}
						imgRef.current = null;
					},
					onChange: setEditableRowKeys,
				}}
			/>
		</>
	);
};
