// 简单的节流函数
export function throttle(func: () => void, limit: number) {
	let throttleTimeout: NodeJS.Timeout | null;
	return function throttledFunc(this: any) {
	  const args:any= arguments;
	  if (!throttleTimeout) {
		throttleTimeout = setTimeout(() => {
		  func.apply(this, args);
		  throttleTimeout = null;
		}, limit);
	  }
	};
  }

  //存储数据时设置过期时间
export function setItemWithExpiry(key: string, value: any, ttl: number) {
	const now = new Date();
  
	// `ttl` 是以毫秒为单位的过期时间
	const item = {
	  value: value,
	  expiry: now.getTime() + ttl,
	};
  
	localStorage.setItem(key, JSON.stringify(item));
  }
  
  //获取数据时检查是否过期
  export function getItemWithExpiry(key: string) {
	const itemStr = localStorage.getItem(key);
  
	// 如果 `item` 不存在，返回 null
	if (!itemStr) {
	  return null;
	}
  
	const item = JSON.parse(itemStr);
	const now = new Date();
  
	// 比较当前时间和保存的过期时间
	if (now.getTime() > item.expiry) {
	  // 如果已过期，删除数据并返回 null
	  localStorage.removeItem(key);
	  return null;
	}
  
	return item.value;
  }

  /**
 *  讲中间字符进行省略处理
 * @param {string} address
 * @param {number} length
 * @return {string}
 */
export function omitCharacters(address, length) {
	return (
		address.substring(0, 5) +
		"..." +
		address.substring(address.length - length)
	);
}