import { Button, message } from "antd";
import React from "react";
import { bindAuthorization } from "../../api/index";
import { omitCharacters } from "../../utils/index.ts";

export default function index({ info, bindToken, bindOut }) {
	const bindLogin = async () => {
		if (window.ethereum) {
			window.ethereum
				.request({ method: "eth_requestAccounts" })
				.then(async (accounts) => {
					const account = accounts[0];

					const message1 = `Timed Tweets ${account}`;
					const signature = await window.ethereum.request({
						method: "personal_sign",
						params: [message1, account],
					});
					// Send the signature to your backend for verification
					let data = {
						signature: signature,
						address: account,
					};
					try {
						let res = await bindAuthorization(data);
						if (res?.token) {
							bindToken({ ...res, address: account });
						}
					} catch (error) {
						message.error(error);
					}
				})
				.catch((error) => {
					console.error("Error:", error);
				});
		} else {
			console.log("MetaMask not found");
		}
	};

	const bindDisconnect = () => {
		bindOut();
	};
	return (
		<div>
			{info ? (
				<Button onClick={bindDisconnect}>
					{" "}
					Disconnect ({omitCharacters(info?.address, 5)})
				</Button>
			) : (
				<Button onClick={bindLogin}>connect</Button>
			)}
		</div>
	);
}
