import React, { useEffect, useState } from "react";
import "./App.css";
import Tabs from "./components/tabs/index.tsx";
import Login from "./components/login/index.tsx";
import { setItemWithExpiry, getItemWithExpiry } from "./utils/index.ts";
import { Button, Empty, Typography } from "antd";

function App() {
	const [info, _info] = useState(null);

	const val = getItemWithExpiry("TIMED_TWEETS");

	const bindToken = (data) => {
		let { address } = data;
		let time = 7 * 24 * 60 * 60 * 1000;
		let str = JSON.stringify(data);
		_info(data);
		setItemWithExpiry("TIMED_TWEETS", str, time);
	};

	const bindOut = () => {
		localStorage.removeItem("TIMED_TWEETS");
		_info(null);
	};

	useEffect(() => {
		if (val) {
			let data = JSON.parse(val);
			_info(data);
		}
	}, [val]);
	return (
		<div className="App">
			<div className="login">
				<Login bindToken={bindToken} bindOut={bindOut} info={info} />
			</div>
			{info ? (
				<Tabs info={info} />
			) : (
				<div>
					<Empty
						image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
						imageStyle={{
							height: 100,
							marginTop:'100px'
						}}
						description={
							<Typography.Text>
								右上方登录后可查看内容
							</Typography.Text>
						}
					></Empty>
				</div>
			)}
		</div>
	);
}

export default App;
