import { Card, message, Select, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
	getUser,
	relationAdd,
	relationDel,
	relationGet,
	UserDel,
	UserGet,
} from "../../api";
import CheckboxContent from "./Checkbox.tsx";
import { omitCharacters } from "../../utils/index.ts";

const { Option } = Select;

function AccountManagement() {
	const [list, _list] = useState([]);
	const [checkboxList, _CheckboxList] = useState([]);

	const [menu, _menu] = useState("");
	const [load, _load] = useState(false);
	const [tabsKey, _tabsKey] = useState("1");

	const [checkedValues, _checkedValues] = useState([]);

	// 获取所有用户
	const getUserList = async () => {
		let res = await getUser();
		console.log("res", res);
		let list = res?.data;
		_menu(list[0]);
		_list(list);
	};
	const getList = async () => {
		let add = tabsKey == "1" ? false : true;
		let res = await relationGet(add, menu);
		let list = res?.data;

		console.log("list", list);

		_CheckboxList(list);
	};

	useEffect(() => {
		getList();
	}, [tabsKey, menu]);

	useEffect(() => {
		getUserList();
	}, []);

	const bindMenu = (item) => {
		_CheckboxList([]);
		_menu(item);
		_checkedValues([])
	};

	const onChange = (key) => {
		console.log(key);
		_CheckboxList([]);
		_tabsKey(key);
		_checkedValues([])
	};

	// 分配
	const distribution = async (values) => {
		console.log("values", values);
		if (values?.length > 0) {
			let data = {
				address: menu,
				twitterUserNames: values,
			};
			_load(true);
			let res = await relationAdd(data);
			if (res) {
				getList();
				message.success(res?.message);
			}
			_load(false);
		}
	};

	// 取消分配
	const unassign = async (values) => {
		console.log("values", values);
		if (values?.length > 0) {
			let data = {
				address: menu,
				twitterUserNames: values,
			};
			_load(true);
			let res = await relationDel(data);
			if (res) {
				getList();
				message.success(res?.message);
			}
			_load(false);
		}
	};

	const items = [
		{
			key: "1",
			label: "分配",
			children: (
				<CheckboxContent
					btnTxt={"分配"}
					load={load}
					onOk={distribution}
					checkboxList={checkboxList}
					checkedValues={checkedValues}
					_checkedValue={_checkedValues}
				/>
			),
		},
		{
			key: "2",
			label: "取消分配",
			children: (
				<CheckboxContent
					btnTxt={"取消分配"}
					load={load}
					onOk={unassign}
					checkboxList={checkboxList}
					checkedValues={checkedValues}
					_checkedValue={_checkedValues}
				/>
			),
		},
	];
	return (
		<div className="accountManagement">
			<Card
				title={
					<div className="menuCardTitle">
						<div>选择用户</div>
					</div>
				}
				bordered={false}
				style={{ width: 300 }}
			>
				<div className="menumenu">
					{list?.map((item: any) => {
						return (
							<div
								key={item}
								className={`${
									menu == item
										? "menumenuItemActive menumenuItem"
										: "menumenuItem"
								}`}
								onClick={() => bindMenu(item)}
							>
								{omitCharacters(item, 5)}
							</div>
						);
					})}
				</div>
			</Card>
			<div className="accountManagementRight">
				<Card
					title={
						<div className="menuCardTitle">
							<div>分配推特号</div>
						</div>
					}
					bordered={false}
				>
					<Tabs
						defaultActiveKey="1"
						items={items}
						onChange={onChange}
					/>
				</Card>
			</div>
		</div>
	);
}

export default AccountManagement;
